<template>
    <div class="content">
        <div class="view">
            <h1>获奖公示</h1>
            <loading :empty="empty" :loading="loading"></loading>
            <div class="list">
                <div :key="item.id" @click="goPage(item.id)" class="item" v-for="item in list">
<!--                    <img :alt="item.title" :src="$url+ item.image" class="img">-->
                    <div>
                        <h1>{{item.title}}</h1>
                        <div>
<!--                            <span>{{item.updatetime | time }}</span>-->
<!--                            <span>{{item.news_sources}}</span>-->
                        </div>

                        <p v-html="item.summary_content"></p>
                    </div>
                </div>

                <p @click="seeMore" class='seeMore' v-if="sumPage > nowIndexs">查看更多</p>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import loading from "../components/loading";

    export default {
        name: "academicExchange",
        data() {
            return {
                list: [],
                nowIndexs: 1,
                sumPage: 1,
                empty: false,
                loading: true
            }
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$get('index/news', {params: {type: 3, page: this.nowIndexs}})
                    .then(res => {
                        this.loading = false
                        let arr = this.list
                        if (res.data) {
                            res.data.data.map(c => {
                                arr.push(c)
                            })
                            this.sumPage = res.data.last_page
                        } else {
                            this.empty = true
                        }

                    })
            },
            goPage(id) {
                this.$router.push({path:'/exchangeView',query:{id:id, type:2}})
            },
            seeMore() {
                if (this.sumPage == this.nowIndexs) {
                    return
                }
                this.nowIndexs++
                this.fetchData()
            }
        },
        created() {
            this.fetchData()
        },
        components: {
            loading
        },
        filters: {
            time(dataStr) {
                if (!dataStr || dataStr == 0) {
                    return '              -'
                } else {
                    return moment(dataStr * 1000).format('YYYY-MM-DD HH:mm:ss')
                }
            }
        }

    }
</script>

<style lang="less" scoped>
    .content {
        width: 100%;
        min-height:calc(100vh - 10.8333vw - 4.1667vw);

        .view {
            width: 52.0833vw;
            height: auto;
            margin: 0 auto;
            padding-top: 4.1667vw;

            h1 {
                font-size: 2.0833vw;
                font-weight: bold;
                color: #333333;

            }

            .list {
                margin-top: 3.1250vw;

                .seeMore {
                    text-align: center;
                    font-size: 0.8333vw;
                    line-height: 1.6667vw;
                    margin-bottom: 2.6042vw;
                    cursor: pointer;
                    color: #797878;
                }

                .item {
                    width: 100%;
                    height: 5.9896vw;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    border-top: 0.0521vw solid #EEEEEE;
                    overflow: hidden;

                    img {
                        width: 12.1875vw;
                        height: 6.8229vw;
                    }

                    >div {
                        height: 1.8229vw;
                        margin-left: 4.1146vw;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        overflow: hidden;
                        flex: 1;
                        overflow: hidden;
                        h1 {
                            font-size: 1.0417vw;
                            font-weight: 550;
                            color: #333333;

                        }

                        div{
                            margin-top: 1.2500vw;
                            margin-bottom: 1.1458vw;
                            span {
                                font-size: 0.6250vw;
                                font-weight: 550;
                                color: #999999;
                                margin-right: 2vw;
                            }
                        }

                        p {
                            width: 100%;
                            height: 4.8229vw;
                            font-size: 0.8333vw;
                            font-weight: 550;
                            color: #333333;
                            line-height: 2.41vw;
                            overflow : hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            /deep/ p{
                                font-size: 0.8333vw !important;
                                line-height: 2.41vw !important;
                                color: #333333 !important;
                                height: 100%;
                                white-space:nowrap;
                                overflow:hidden;
                                text-overflow:ellipsis;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

